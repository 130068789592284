import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import browserUpdate from 'browser-update';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import './i18n';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://38a9b553ef394f80b35f79518e1f0030@bugmon.sum1.ru/5",
    tracesSampleRate: 0.3,
    release: import.meta.env.VITE_NAME + '@' + import.meta.env.VITE_VERSION
});

const generateClassName = createGenerateClassName({
    disableGlobal: false,
    productionPrefix: 'elk',
    // seed: 'config'
});

browserUpdate({
    required: {
        e:-6,
        f:-6,
        o:-6,
        s:-2,
        c:-6,
        y:-6,
        v:-6
    },
    reminder: 24,
    insecure:true,
    unsupported: true
});



ReactDOM.render(
    <StylesProvider generateClassName={generateClassName} injectFirst>
            <App/>
    </StylesProvider>,
    document.getElementById('root'));

// Это необходимо для работы пакета why-did-you-update
// Пакет указывает, какие из компонентов перерисовываются (render()) без необходимости (без изменения props)
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        //.then(initialiseState);
        .then(function (registration) {
            console.log('Registration successful, scope is:', registration.scope);
        }).catch(function (err) {
        console.log('Service worker registration failed, error:', err);
    });
} else {
    console.warn('Service workers aren\'t supported in this browser.');
}
