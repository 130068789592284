import { TOGGLE_DRAWER_LEFT } from "../constants/actionTypes";

const initialState = {
    drawerLeft: {open: false}
};

export function elkPage(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_DRAWER_LEFT:
            return Object.assign({}, state, {
                drawerLeft: {
                    open: !state.drawerLeft.open
                }
            });
        default:
            return state;
    }
}