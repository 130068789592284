import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {language} from '@ksbteam/core/api/DummyTranslate';
import ru from './static/translation/ru.json';
import en from './static/translation/en.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en,
            ru
        },
        lng: language,
        fallbackLng: "ru",

        interpolation: {
            escapeValue: false
        }
    });


export default i18n;