import Cookies from 'universal-cookie';
import RestClient from '@ksbteam/core/api/RestClient';
import {language} from '@ksbteam/core/api/DummyTranslate';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const cookies = new Cookies();

export default class ElkUser {
    static #instance = null;
    static #fingerprint = null;

    constructor() {
        if (ElkUser.#instance){
            return ElkUser.#instance;
        }
        this.checkServiceMode = true;
        this.generateFingerprint().then(fp => ElkUser.#fingerprint = fp);
        ElkUser.#instance = this;
    }

    async generateFingerprint() {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        // const visitorId = result.visitorId;
        // cookies.set('fingerPrint', visitorId, {path: '/'});
        // return visitorId;
        return result.visitorId;
    }

    get fingerPrint() {
        return ElkUser.#fingerprint;
    }

    get authToken() {
        return cookies.get('authToken');
    }

    set authToken(value) {
        cookies.set('authToken', value, {path: '/'});
    }

    get xmppToken() {
        return cookies.get('xmppToken');
    }

    set xmppToken(value) {
        cookies.set('xmppToken', value, {path: '/'});
    }

    get twoStepAuth() {
        return cookies.get('twoStepAuth');
    }

    set twoStepAuth(value) {
        cookies.set('twoStepAuth', value, {path: '/'});
    }

    get userLogin() {
        return cookies.get('userLogin');
    }

    set userLogin(value) {
        cookies.set('userLogin', value, {path: '/'});
    }

    get ignoreSM() {
        return cookies.get('ignoreSM');
    }

    get userID() {
        return cookies.get('userID');
    }

    set userID(value) {
        cookies.set('userID', value, {path: '/'});
    }

    get accessType(){
        return cookies.get('at');
    }

    set accessType(value){
        cookies.set('at', value, {path: '/'});
    }

    get lastActive(){
        return cookies.get('last-active');
    }

    set lastActive(value){
        cookies.set('last-active', value, {path: '/'});
    }

    get isMobile(){
        return sessionStorage.getItem('is_mobile') === '1';
    }

    loggedIn() {
        return !!this.authToken && this.authToken.length;
    }

    passedTwoStepAuth() {
        return !!this.twoStepAuth && +this.twoStepAuth === 1;
    }

    async login(login, password) {
        const client = new RestClient({
            host: import.meta.env.VITE_REST_SERVICE,
            authType: 'Basic',
            authToken: btoa(login + ':' + password),
            logoutUnauthorized: false
        });
        let response = await Promise.all([
            client.get(`icrobjects/login/token?fingerprint=${this.fingerPrint}`),
            client.get('icrobjects/login/user-info')
        ]);

        this.authToken = response[0];
        this.xmppToken = '';
        this.userLogin = login;
        this.accessType = response[1].access_type || '';
        this.lastActive = Date.now();

        if (response[2]?.['two_factor_enabled'] === false){
            this.twoStepAuth = 1;
        }
        let url;
        switch (this.accessType){
            case 'elk':
                url = 'info';
                break;
            case 'lu_elk':
                url = 'light-info';
                break;
            default:
                break;
        }

        if (url){
            let resp = await client.get(`icrobjects/cabinet/${url}`);
            this.userID = resp['virtual_id'];
        } else if (parseInt(login) !== NaN){
            this.userID = login;
        }

    }

    async passTwoStepAuth() {
        this.twoStepAuth = 1;
    }

    async logout() {
        this.authToken = '';
        this.twoStepAuth = 0;
        this.userLogin = '';
        this.userID = 0;
        this.xmppToken = '';
        this.accessType = '';
        this.lastActive = '';
        this.goLoginPage();
    }

    goLoginPage() {
        if (!this.authToken.length && window.location.href.indexOf(`/${language}/login`) === -1) {
            window.location.href = `/${language}/login`;
        }
    }

    goMainPage() {
        if (this.authToken.length) {
            window.location.href = `/${language}/main`;
        }
    }

    goMaintenancePage() {
        if (!this.ignoreSM) {
            window.location.href = `/${language}/maintenance`;
        }
    }

    goAuth2Step() {
        this.logout();
    }
}
