import { language } from "@ksbteam/core/api/DummyTranslate";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackSharp } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import DataTransferDialog from "../DataTransferDialog/DataTransferDialog";
import { useEffectOnce } from "react-use";
import RestClient from "@ksbteam/core/api/RestClient";
import ElkUser from "../../../api/ElkUser";

const useStyles = makeStyles((theme) => ({
    root: {
        "& p": {
            marginBottom: theme.spacing(),
        },
        "& ul li": {
            marginInlineStart: "2.5em",
        },
        "& ol li": {
            marginInlineStart: "1em",
        },
        "& .section-title": {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
}));

export default function AboutMSP() {
    const [inited, setInited] = React.useState(false);
    const [isAgree, setIsAgree] = React.useState(false);
    const [currentLink, setCurrentLink] = React.useState("");
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const user = React.useMemo(() => new ElkUser(), []);
    const client = React.useMemo(
        () =>
            new RestClient({
                host: import.meta.env.VITE_REST_SERVICE,
                authToken: user.authToken,
                user,
                logoutUnauthorized: user.isMobile ? false : true,
                headers: {
                    application: user.isMobile ? "mobile" : "web",
                },
            }),
        [user]
    );

    const handleAgreeClick = async () => {
        try {
            await client.post("/icrobjects/cabinet/agree-transf-msp/", {
                agree_transf_msp: 1 ,
            });
            setIsAgree(true);
            const url = currentLink + `?token=${user.authToken} + "&utm_source=banner&utm_medium=roscongress&utm_campaign=link_on_landing_page"`;
            window.open(url, "_blank");
            setIsDialogOpen(false);
        } catch (e) {
            console.error(e);
            setIsAgree(false);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        setCurrentLink(e.target.href);
        if (isAgree) {
            const url = e.target.href + `?token=${user.authToken}`;
            window.open(url, "_blank");
        } else setIsDialogOpen(true);
    };

    useEffectOnce(() => {
        (async () => {
            let info = await client.get("/icrobjects/cabinet/info");
            setIsAgree(
                info.is_agree_transferdata_msp &&
                    info.is_agree_transferdata_msp === "1"
                    ? true
                    : false
            );
            setInited(true);
        })();
    });
    return !inited ? (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
            }}
        >
            <CircularProgress color="inherit" size={64} />
        </div>
    ) : (
        <>
            {language === "en" ? <En /> : <Ru />}
            <DataTransferDialog
                isDialogOpen={isDialogOpen}
                handleDialogClose={() => setIsDialogOpen(false)}
                handleAgreeClick={handleAgreeClick}
            />
        </>
    );

    function Ru() {
        const classes = useStyles();
        const history = useHistory();
        return (
            <div className={classes.root}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ maxWidth: "20%", marginBottom: "16px" }}
                    onClick={() =>
                        history.push(`/${language}/sme-cooperation/`)
                    }
                >
                    <ArrowBackSharp
                        fontSize="small"
                        style={{ marginRight: "8px" }}
                    />{" "}
                    Назад
                </Button>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/bitrix/admin/nota.roscongress.php"
                        target="_blank"
                        onClick={handleClick}
                    >
                        Площадка бизнес-партнерств
                    </a>{" "}
                    Цифровой платформы МСП.РФ (Small and medium-sized
                    enterprises – Digital ecosystem) объединяет российских и
                    иностранных поставщиков товаров и услуг, заказчиков и
                    инвесторов.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/bitrix/admin/nota.roscongress.php"
                        target="_blank"
                        onClick={handleClick}
                    >
                        Найти партнеров
                    </a>{" "}
                </Typography>
                <Typography gutterBottom>
                    Цель{" "}
                    <a
                        href="https://партнер.мсп.рф/bitrix/admin/nota.roscongress.php"
                        target="_blank"
                        onClick={handleClick}
                    >
                        Площадки бизнес-партнерства
                    </a>{" "}
                    – расширить взаимодействие российских компаний с зарубежными
                    партнерами в сфере производства, сделать инвестиционное,
                    технологическое и бизнес-сотрудничество более доступным,
                    быстрым и эффективным.
                </Typography>
                <Typography gutterBottom>Поможет:</Typography>
                <ul>
                    <Typography component={"li"} gutterBottom>
                        найти продукцию
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        предложить продукцию
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        стать инвестором
                    </Typography>
                </ul>
                <Typography gutterBottom>
                    Получите контакты проверенных партнеров бесплатно. Подойдет,
                    если:
                </Typography>
                <ul>
                    <Typography component={"li"} gutterBottom>
                        ищете поставщиков товаров и услуг,
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        готовы выходить на новые рынки сбыта продукции,
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        готовы инвестировать в бизнес-проекты или ищете
                        инвесторов.
                    </Typography>
                </ul>
                <Typography gutterBottom>
                    Витрина компаний и продукции
                </Typography>
                <Typography gutterBottom>
                    Проверяем всех поставщиков. Помогаем рассказать о своих
                    товарах и услугах. Посмотрите продукцию российских и
                    иностранных компаний и выберите интересующую. Запросите
                    контакты компании, свяжитесь с ее представителями и начните
                    сотрудничество. Разместите на витрине информацию о себе и
                    своей продукции – это бесплатно.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/services/development/not-food?utm_source=banner&utm_medium=roscongress&utm_campaign=link_on_landing_page"
                        target="_blank"
                    >
                        Посмотреть продукцию
                    </a>
                </Typography>
                <Typography gutterBottom>
                    Витрина спроса на продукцию
                </Typography>
                <Typography gutterBottom>
                    Узнайте, какие товары, услуги, сырье или комплектующие ищут
                    торговые сети, крупные российские и иностранные компании, и
                    ответьте на их запрос. Предоставим контакты менеджера сети
                    или компании для обсуждения условий сотрудничества.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/services/development/retail_noprod?utm_source=banner&utm_medium=roscongress&utm_campaign=link_on_landing_page"
                        target="_blank"
                    >
                        Узнать потребности
                    </a>
                </Typography>
                <Typography gutterBottom>
                    Витрина инвестиционных проектов
                </Typography>
                <Typography gutterBottom>
                    Собрали перспективные инвестиционные проекты на разных
                    этапах жизненного цикла, в разных странах и отраслях.
                    Находите и сравнивайте проекты или презентуйте собственные.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/services/investment-projects?utm_source=banner&utm_medium=roscongress&utm_campaign=link_on_landing_page"
                        target="_blank"
                    >
                        Найти инвестпроекты
                    </a>
                </Typography>
            </div>
        );
    }

    function En() {
        const classes = useStyles();
        const history = useHistory();
        return (
            <div className={classes.root}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ maxWidth: "20%", marginBottom: "16px" }}
                    onClick={() =>
                        history.push(`/${language}/sme-cooperation/`)
                    }
                >
                    <ArrowBackSharp
                        fontSize="small"
                        style={{ marginRight: "8px" }}
                    />{" "}
                    Back
                </Button>
                <Typography gutterBottom>
                    The{" "}
                    <a
                        href="https://партнер.мсп.рф/bitrix/admin/nota.roscongress.php"
                        target="_blank"
                        onClick={handleClick}
                    >
                        Business Partnership Platform
                    </a>{" "}
                    is a feature offered by the SME Digital Ecosystem helping to
                    bring together Russian and foreign suppliers of goods and
                    services, customers, and investors.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/bitrix/admin/nota.roscongress.php"
                        target="_blank"
                        onClick={handleClick}
                    >
                        Find partners
                    </a>{" "}
                </Typography>
                <Typography gutterBottom>
                    The{" "}
                    <a
                        href="https://партнер.мсп.рф/bitrix/admin/nota.roscongress.php"
                        target="_blank"
                        onClick={handleClick}
                    >
                        Business Partnership Platform
                    </a>{" "}
                    has been developed with several aims in mind. These include
                    fostering manufacturing ties between Russian companies and
                    foreign partners, and to make it easier, faster and more
                    effective for companies to work together on matters
                    concerning investment, technology and business.
                </Typography>
                <Typography gutterBottom>
                    It can help with the following:
                </Typography>
                <ul>
                    <Typography component={"li"} gutterBottom>
                        Finding products
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        Offering products
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        Becoming an investor
                    </Typography>
                </ul>
                <Typography gutterBottom>
                    Users have free access to the contact information of
                    verified partners. This feature is for you if:
                </Typography>
                <ul>
                    <Typography component={"li"} gutterBottom>
                        You are looking for suppliers of goods and services
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        You are in a position to enter new sales markets
                    </Typography>
                    <Typography component={"li"} gutterBottom>
                        You are looking to invest in business initiatives or are
                        looking for investors
                    </Typography>
                </ul>
                <Typography gutterBottom>
                    <strong>Overview of companies and products</strong>
                </Typography>
                <Typography gutterBottom>
                    All listed suppliers go through a verification process, and
                    we help them to communicate information on their products
                    and services. Users can look at products offered by Russian
                    and international companies, and select items of interest.
                    All that remains is to request a company’s contact
                    information, get in touch, and start working together.
                    Information about your company and the products it offers
                    may be posted for free.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/services/development/not-food?utm_source=banner&utm_medium=roscongress&utm_campaign=link_on_landing_page"
                        target="_blank"
                    >
                        View products
                    </a>
                </Typography>
                <Typography gutterBottom>
                    <strong>Overview of demand for products</strong>
                </Typography>
                <Typography gutterBottom>
                    Find out what goods, services, raw materials or components
                    are in demand among retail chains and major Russian and
                    international companies, and respond to their requests. We
                    will provide the contact details of a retail chain or
                    company’s relevant manager to discuss potential cooperation.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/services/development/retail_noprod?utm_source=banner&utm_medium=roscongress&utm_campaign=link_on_landing_page"
                        target="_blank"
                    >
                        Find out about current needs
                    </a>
                </Typography>
                <Typography gutterBottom>
                    <strong>Overview of investment projects</strong>
                </Typography>
                <Typography gutterBottom>
                    We have brought together a range of promising investment
                    projects from across various countries, industries, and
                    stages of the life cycle. Find and compare different
                    projects, and present your own.
                </Typography>
                <Typography gutterBottom>
                    <a
                        href="https://партнер.мсп.рф/services/investment-projects?utm_source=banner&utm_medium=roscongress&utm_campaign=link_on_landing_page"
                        target="_blank"
                    >
                        Find investment projects
                    </a>
                </Typography>
            </div>
        );
    }
}
