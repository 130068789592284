import React from 'react';
import Loadable from './Loadable';
import Loader from '../components/Loader';
import lazyWithRetry from './lazyWithRetry';
import MessageIcon from '@material-ui/icons/Message';
import ForumIcon from '@material-ui/icons/Forum';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PollIcon from '@material-ui/icons/Poll';
import InfoIcon from '@material-ui/icons/Info';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import SettingsIcon from '@material-ui/icons/Settings';
import {language, t} from '@ksbteam/core/api/DummyTranslate';
import { Translation } from 'react-i18next';
import {Redirect} from "react-router";
import AboutMSP from '../components/Marketplace/AboutMSP';

const FrontPage = Loadable(lazyWithRetry(() => import('../containers/FrontPage')));
const ElkPage = Loadable(lazyWithRetry(() => import('../containers/ElkPage')));
const MobilePage = Loadable(lazyWithRetry(() => import('../containers/MobilePage')));
const LoginPage = Loadable(lazyWithRetry(() => import('../containers/LoginPage')));
const MaintenancePage = Loadable(lazyWithRetry(() => import('../containers/MaintenancePage')));
const AboutAccountPage = Loadable(lazyWithRetry(() => import('../containers/AboutAccountPage')));
const RegistrationPage = Loadable(lazyWithRetry(() => import('../containers/RegistrationPage')));
const MobileRegistrationPage = Loadable(lazyWithRetry(() => import('../components/Mobile/MobileRegistration/MobileFullRegistrationProjects')));
const RegistrationProjectsPage = Loadable(lazyWithRetry(() => import('../containers/RegistrationProjectsPage')));
const TwoStepAuthPage = Loadable(lazyWithRetry(() => import('../containers/TwoStepAuthPage')));
const Main = Loadable(lazyWithRetry(() => import('../components/Main')));
const RegList = Loadable(lazyWithRetry(() => import('../components/RegList')));
const PersonalData = Loadable(lazyWithRetry(() => import('../components/PersonalData')));
const Exchange = Loadable(lazyWithRetry(() => import('../components/Exchange')));
const Events = Loadable(lazyWithRetry(() => import('../components/Events')));
const Event = Loadable(lazyWithRetry(() => import('../components/Event')));
const Materials = Loadable(lazyWithRetry(() => import('../components/Materials')));
const MaterialsViewPage = Loadable(lazyWithRetry(() => import('../components/Materials/MaterialsViewPage')));
const Polls = Loadable(lazyWithRetry(() => import('../components/Polls')));
const FakeForm = Loadable(lazyWithRetry(() => import('../components/FakeForm')));
const PaymentStatusForm = Loadable(lazyWithRetry(() => import('../components/Payments')));
const ContactMessage = Loadable(lazyWithRetry(() => import('../components/ContactMessage')));
const InformationMaterials = Loadable(lazyWithRetry(() => import('../components/InformationMaterials')));
const VirtualEvents = Loadable(lazyWithRetry(() => import('../components/VirtualEvents')));
const VEventPage = Loadable(lazyWithRetry(() => import('../components/VirtualEvents/VEventPage')));
const Faq = Loadable(lazyWithRetry(() => import('../components/Faq')));
const Services = Loadable(lazyWithRetry(() => import('../components/Services')));
const Profile = Loadable(lazyWithRetry(() => import('../components/Profile')));
const Community = Loadable(lazyWithRetry(() => import('../components/Community')));
const ExpertNetwork = Loadable(lazyWithRetry(() => import('../components/ExpertNetwork')));
const FrameCalendar = Loadable(lazyWithRetry(() => import('../components/FrameCalendar')));
const Calendar = Loadable(lazyWithRetry(() => import('../components/Calendar')));
const RcClub = Loadable(lazyWithRetry(() => import('../components/RcClub')));
const RcClubUploadAgreementPage = Loadable(lazyWithRetry(() => import('../components/RcClub/UploadAgreementPage')));
const RcClubRenewPage = Loadable(lazyWithRetry(() => import('../components/RcClub/PageRenew')));
const FrameExpertChat = Loadable(lazyWithRetry(() => import('../components/FrameExpertChat')));
const Live = Loadable(lazyWithRetry(() => import('../components/Live')));
const RestorePassword = Loadable(lazyWithRetry(() => import('../components/RestorePassword')));
const RestorePasswordMobile = Loadable(lazyWithRetry(() => import('../components/Mobile/RestorePasswordMobile')));
const Nft = Loadable(lazyWithRetry(() => import('../components/Nft')));
const Networking = Loadable(lazyWithRetry(() => import('../components/Networking')));
const MobilePersonalInfo = Loadable(lazyWithRetry(() => import('../components/Mobile/PersonalInfo')));
const FrameChat = Loadable(lazyWithRetry(() => import('../components/FrameChat')));
const ExchangeCardPage = Loadable(lazyWithRetry(() => import('../components/Exchange/CardPage')));
const ExchangeCardCreate = Loadable(lazyWithRetry(() => import('../components/Exchange/CardCreate')));
const ExchangeCardEdit = Loadable(lazyWithRetry(() => import('../components/Exchange/CardEdit')));
const FullRegistration = Loadable(lazyWithRetry(() => import('../components/Registration/FullRegistration')));
const Biography = Loadable(lazyWithRetry(() => import('../components/Biography')));
const MembersList = Loadable(lazyWithRetry(() => import('../components/MembersList')));
const ConstructionMessage = Loadable(lazyWithRetry(() => import('../components/ConstructionMessage')));
const MobileChat = Loadable(lazyWithRetry(() => import('../components/Mobile/Chat')));
const MobileBusinessCard = Loadable(lazyWithRetry(() => import('../components/Mobile/BusinessCard')));
const SmeMain = Loadable(lazyWithRetry(() => import('../components/Marketplace/Main')));
const SmeCreateForm = Loadable(lazyWithRetry(() => import('../components/Marketplace/CreateForm')));
const SmeCardsCategory = Loadable(lazyWithRetry(() => import('../components/Marketplace/CardsCategory')));
const SmeMyCards = Loadable(lazyWithRetry(() => import('../components/Marketplace/MyCards')));
const SmeSuccessMessage = Loadable(lazyWithRetry(() => import('../components/Marketplace/SuccessMessage')));
const SmeViewCategory = Loadable(lazyWithRetry(() => import('../components/Marketplace/ViewCategory')));
const SmeViewCard = Loadable(lazyWithRetry(() => import('../components/Marketplace/ViewCard')));
const DubaiOperaBall = Loadable(lazyWithRetry(() => import('../components/OtherEvents/DubaiOperaBall')));
const MobileNews = Loadable(lazyWithRetry(() => import('../components/Mobile/News')));
const MobileNewsContent = Loadable(lazyWithRetry(() => import('../components/Mobile/News/Content')));
const MobileAnalytics = Loadable(lazyWithRetry(() => import('../components/Mobile/Analytics')));
const MobileEvent = Loadable(lazyWithRetry(() => import('../components/Mobile/Event')));
const MobileHome = Loadable(lazyWithRetry(() => import('../components/Mobile/Home')));
const MobileSpeakers = Loadable(lazyWithRetry(() => import('../components/Mobile/Event/Speakers')));
const MobileSpeakersCard = Loadable(lazyWithRetry(() => import('../components/Mobile/Event/Speakers/Card')));
const MobilePartners = Loadable(lazyWithRetry(() => import('../components/Mobile/Event/Partners')));
const MobilePersonalProfile = Loadable(lazyWithRetry(() => import('../components/Mobile/PersonalProfile')));
const MobileAboutContacts = Loadable(lazyWithRetry(() => import('../components/Mobile/About/Contacts')));
const MobileAboutFaq = Loadable(lazyWithRetry(() => import('../components/Mobile/About/Faq')));
const MobileAboutProjects = Loadable(lazyWithRetry(() => import('../components/Mobile/About/Projects')));
const MobileAboutEvent = Loadable(lazyWithRetry(() => import('../components/Mobile/About/Projects/ProjectInfo')));
const MobileVisitCard = Loadable(lazyWithRetry(() => import('../components/Mobile/VisitCard')));
const MobileQr = Loadable(lazyWithRetry(() => import('../components/Mobile/Qr')));
const MobileAbout = Loadable(lazyWithRetry(() => import('../components/Mobile/About')));
const MobileServices = Loadable(lazyWithRetry(() => import('../components/Mobile/Services')));
const MobileCalendar = Loadable(lazyWithRetry(() => import('../components/Mobile/Calendar')));
const MobileJusticeITEvent = Loadable(lazyWithRetry(() => import('../components/Mobile/JusticeIT/Event/Event')));
const MobileNotifications = Loadable(lazyWithRetry(() => import('../components/Mobile/Notifications')));
// const MobileTest = Loadable(lazyWithRetry(() => import('../components/Mobile/TestPage')));
const MobileMyEvents = Loadable(lazyWithRetry(()=> import(`../components/Mobile/MyEvents`)));
const MobileStatuses = Loadable(lazyWithRetry(()=> import(`../components/Mobile/Statuses`)));
const MobileBdk = Loadable(lazyWithRetry(()=> import(`../components/Mobile/Bdk`)));
const MobileJusticeITEventSurvey = Loadable(lazyWithRetry(() => import('../components/Mobile/Event/Survey/Survey')));
const MobileCalendarPage = ({match}) => <MobilePage noPadding={true}><MobileCalendar /></MobilePage>;
const MobileAboutPage = ({match}) => <MobilePage noPadding={true}><MobileAbout /></MobilePage>;
const MobileVisitCardPage = ({match}) => <MobilePage noPadding={true}><MobileVisitCard /></MobilePage>;
const MobileQrPage = ({match}) => <MobilePage noPadding={true}><MobileQr /></MobilePage>;
const MobileAboutContactsPage = ({match}) => <MobilePage noPadding={true}><MobileAboutContacts /></MobilePage>;
const MobileAboutFaqPage = ({match}) => <MobilePage noPadding={true}><MobileAboutFaq /></MobilePage>;
const MobileAboutProjectsPage = ({match}) => <MobilePage noPadding={true}><MobileAboutProjects /></MobilePage>;
const MobileAboutEvent1Page = ({match}) => <MobilePage noPadding={true}><MobileAboutEvent /></MobilePage>;
const MobilePersonalProfilePage = ({match}) => <MobilePage noPadding={true}><MobilePersonalProfile /></MobilePage>;
const MobileSpeakersPage = ({match}) => <MobilePage noPadding={true}><MobileSpeakers /></MobilePage>;
const MobileSpeakersCardPage = ({match}) => <MobilePage noPadding={true}><MobileSpeakersCard /></MobilePage>;
const MobilePartnersPage = ({match}) => <MobilePage noPadding={true}><MobilePartners /></MobilePage>;
const MobileEventPage = ({match}) => <MobilePage noPadding={true}><MobileEvent /></MobilePage>;
const MobileMyEventsPage = ({match}) => <MobilePage noPadding={true}><MobileMyEvents /></MobilePage>;
const MobileHomePage = ({match}) => <MobilePage noPadding={true}><MobileHome /></MobilePage>;
const MobileNewsPage = ({match}) => <MobilePage noPadding={true}><MobileNews /></MobilePage>;
const MobileNewsContentPage = ({match}) => <MobilePage noPadding={true}><MobileNewsContent /></MobilePage>;
const MobileAnalyticsPage = ({match}) => <MobilePage noPadding={true}><MobileAnalytics /></MobilePage>;
const MobileBusinessCardPage = ({match}) => <MobilePage noPadding={true}><MobileBusinessCard elkId={match.params.id}/></MobilePage>;
const MobilePersonalPage = () => <MobilePage><MobilePersonalInfo /></MobilePage>;
const MobileChatPage = (props) => <MobilePage noPadding={true} fullWidth={true}> <MobileChat {...props}/></MobilePage>;
const MobileServicesPage = (props) => <MobilePage noPadding={true}><MobileServices {...props}/></MobilePage>;
const MobileJusticeITEventPage = (props) => <MobilePage noPadding={true}><MobileJusticeITEvent {...props}/></MobilePage>;
const MobileJusticeITEventSurveyPage = (props) => <MobilePage noPadding={true}><MobileJusticeITEventSurvey {...props}/></MobilePage>;
const MobileNotificationsPage= (props) => <MobilePage noPadding={true} fullWidth={true}><MobileNotifications {...props}/></MobilePage>;
// const MobileTestPage= (props) => <MobilePage noPadding={true} fullWidth={true}><MobileTest {...props}/></MobilePage>;
const MobileRestorePasswordPage= (props) => <MobilePage noPadding={true} fullWidth={true}><RestorePasswordMobile {...props}/></MobilePage>;
const MobileStatusesPage= (props) => <MobilePage noPadding={true} fullWidth={true}><MobileStatuses {...props}/></MobilePage>;
const MobileBdkPage= (props) => <MobilePage noPadding={true} fullWidth={true}><MobileBdk {...props}/></MobilePage>;
const MainPage = () => <ElkPage><Main/></ElkPage>;
const PersonalDataPage = () => <ElkPage title={t('Personal data')}><PersonalData/></ElkPage>;
const MobilePersonalDataPage = () => <MobilePage title={t('Personal data')}><PersonalData/></MobilePage>;
const ExchangePage = () => <ElkPage><Exchange/></ElkPage>;
const ExchangeCardCreatePage = () => <ElkPage><ExchangeCardCreate/></ElkPage>;
const ExchangeCardEditPage = ({match}) => <ElkPage><ExchangeCardEdit id={match.params.id || null}/></ElkPage>;
const EventsPage = () => <ElkPage fullWidth={true}><Events/></ElkPage>;
const AnalyticsPage = () => <ElkPage title={t('Analytics')}><Materials section={'analytics'}/></ElkPage>;
const NewsPage = () => <ElkPage title={t('News')}><Materials section={'news'}/></ElkPage>;
const MaterialPage = (props) => <ElkPage><MaterialsViewPage {...props}/></ElkPage>;
const FakeFormPage = (props) => <ElkPage fullWidth={true} noPadding={true}><FakeForm {...props}/></ElkPage>;

const ChatPage = () => <ElkPage fullWidth={true} title={t('Dialogs')}><FrameChat/></ElkPage>;
const LivePage = () => <ElkPage><Live/></ElkPage>;

const VirtualEventsPage = (props) => <ElkPage title={t('Events')}><VirtualEvents {...props}/></ElkPage>;

const RegPage = (props) => <ElkPage fullWidth={true}><FullRegistration {...props}/></ElkPage>

const PollsPage = () => <ElkPage title={language === 'ru'? 'Опросы' : 'Polls'}><Polls/></ElkPage>;

const CalendarPage = () => <ElkPage fullWidth={true} title={t('Event calendar')}><FrameCalendar/></ElkPage>;

const ExpertNetworkPage = () => <ElkPage title={t('Expert network')}><ExpertNetwork/></ElkPage>;

const FrameExpertChatPage = (props) => <ElkPage fullWidth={true} noPadding={true}><FrameExpertChat {...props}/></ElkPage>;

const NftPage = (props) => <ElkPage><Nft {...props} /></ElkPage>

const NetworkingPage = (props) => <ElkPage><Networking {...props} /></ElkPage>

function EventPage({match}) {
    return <ElkPage fullWidth={true} noPadding={true}><Event code={match.params.code || null}/></ElkPage>;
}

function PaymentPage({match}) {
    return <ElkPage><PaymentStatusForm code={match.params.code || null}/></ElkPage>;
}

function RegistrationProjects({match}) {
    return <RegistrationProjectsPage projects={match.params.projects || null} />
}

function Registration({match}) {
    return <RegistrationPage projects={match.params.projects || null} />
}

const BiographyPage = () => <Translation>
    {
        (t) => <ElkPage title={t('main.my_bio')}><Biography/></ElkPage>
    }
</Translation>

const MembersListPage = () => <ElkPage title={'Пользователи ЕЛК'}><MembersList/></ElkPage>

const ConstructionPage = () => <ElkPage><ConstructionMessage/></ElkPage>;
const MobileConstructionPage = () => <MobilePage><ConstructionMessage/></MobilePage>;

const ContactPage = () => <ElkPage><ContactMessage/></ElkPage>;

const ProfilePage = () => <ElkPage><Profile/></ElkPage>;

const CommunityPage = () => <ElkPage><Community/></ElkPage>;

const InformationMaterialsPage = () => <ElkPage><InformationMaterials/></ElkPage>;

const FaqPage = () => <ElkPage><Faq/></ElkPage>;
const ServicesPage = () => <Translation>
    {
        (t) => <ElkPage title={t('main.services')}><Services/></ElkPage>
    }
</Translation>;

const DubaiOperaBallPage = () => <ElkPage fullWidth={true} noPadding={true}><DubaiOperaBall/></ElkPage>;

const RcClubPage = () => <ElkPage fullWidth={true} noPadding={true}><RcClub/></ElkPage>;
const RcClubUploadPage = () => <ElkPage fullWidth={true} noPadding={true}><RcClubUploadAgreementPage/></ElkPage>;
const RcClubRenewCardPage = () => <ElkPage fullWidth={true} noPadding={true}><RcClubRenewPage/></ElkPage>;

const SmeMainPage = () => <ElkPage><SmeMain/></ElkPage>;
const SmeCreateFormPage = () => <ElkPage><SmeCreateForm/></ElkPage>;
const SmeCardsCategoryPage = () => <ElkPage><SmeCardsCategory/></ElkPage>;
const SmeViewCategoryPage = (props) => <ElkPage><SmeViewCategory {...props}/></ElkPage>;
const SmeViewCardPage = (props) => <ElkPage><SmeViewCard {...props}/></ElkPage>;
const SmeSuccessMessagePage = () => <ElkPage><SmeSuccessMessage/></ElkPage>;
const SmeMyCardsPage = () => <ElkPage><SmeMyCards/></ElkPage>;
const AboutMSPPage = () => <ElkPage><AboutMSP/></ElkPage>;

const LoaderPage = () => <Loader isOpen={true} />

class MainMenu {
    constructor() {
        this.items = [
            {
                url: `/${language}`,
                component: FrontPage
            },
            {
                url: `/`,
                component: FrontPage
            },
            {
                url: `/${language}/login`,
                component: LoginPage,
            },
            {
                url: `/${language}/nft-marketplace`,
                component: NftPage,
            },
            {
                url: `/${language}/registration/:projects?`,
                component: Registration,
            },
            {
                url: `/${language}/registration-projects/:projects?`,
                component: RegistrationProjects,
            },
            {
                url: `/${language}/two-step-auth`,
                component: TwoStepAuthPage,
            },
            {
                url: `/${language}/virtual-event/:id`,
                component: VEventPage,
            },
            {
                title: t('Main'),
                url: `/${language}/main`,
                component: MainPage,
                areas: [`header`]
            },
            {
                title: t('Events'),
                url: `/${language}/events`,
                component: EventsPage,
                subMenu: {
                    items: [
                        {
                            url: `/${language}/event/dresden_ball`,
                            component: () => {
                                return <Redirect to={`/${language}/dubai-opera-ball`}/>
                            },
                            full: true
                        },
                        {
                            url: `/${language}/event/:code`,
                            component: EventPage,
                            full: false,
                        }
                    ]
                },
                areas: [`header`]
            },
            {
                title: t('Card'),
                url: `/${language}/exchange/card/:id`,
                component: ExchangeCardPage,
                areas: [],
                full: true,
            },
            {
                title: t('Create business card'),
                url: `/${language}/exchange/card-create`,
                component: ExchangeCardCreatePage,
                areas: [],
                full: true,
            },
            {
                title: t('Edit business card'),
                url: `/${language}/exchange/card-edit/:id`,
                component: ExchangeCardEditPage,
                areas: [],
                full: true,
            },
            {
                title: t('News'),
                url: `/${language}/materials/news`,
                component: NewsPage,
                areas: [`header`]
            },
            {
                title: t('Analytics'),
                url: `/${language}/materials/analytics`,
                component: AnalyticsPage,
                areas: [`header`]
            },
            {
                title: t('Project Exchange'),
                url: `/${language}/exchange`,
                component: ExchangePage,
                areas: [],
                full: true,
            },
            // {
            //     title: t('Community'),
            //     url: `/${language}/community`,
            //     component: CommunityPage,
            //     areas: [`header`],
            //     full: true,
            // },
            {
                title: t('Expert network'),
                url: `/${language}/expert-network`,
                component: ExpertNetworkPage,
                areas: [`header`],
                full: true,
            },
            {
                title: 'Пользователи ЕЛК',
                url: `/${language}/members`,
                component: MembersListPage,
                full: true,
                areas: [`header`]
            },
            // {
            //     title: `Биржа проектов`,
            //     url: `/projects-exchange`,
            //     component: ConstructionPage,
            //     areas: [`header`]
            // },
            {
                title: t('Virtual events'),
                url: `/virtual-events`,
                component: VirtualEventsPage,
                areas: [`header`],
                pageCode: 'virtual-events',
                checkAccess: true
            },
            {
                title: t('Services'),
                url: `/${language}/services`,
                component: ServicesPage,
                areas: [`header`]
            },
            {
                title: t('RC Club'),
                url: `/${language}/rc-club`,
                component: RcClubPage,
                areas: [`header`],
                full: true,
                className: 'rc-club',
                hasTooltip: true,
                pageCode: 'rc-club'
            },
            {
                title: t('Full version'),
                url: `/${language}/full-access`,
                component: RegPage,
                areas: [`header`],
                onlyLight: true,
                pageCode: 'full-registration',
            },
            {
                title: t('Expert network chat'),
                url: `/${language}/expert-network-chat`,
                component: FrameExpertChatPage,
                areas: [`header-icons`],
                icon: <ForumIcon/>,
                full: true,
            },
            {
                title: t('LIVE'),
                url: `/${language}/live`,
                component: LivePage,
                areas: [`header-icons`],
                icon: <LiveTvIcon/>
            },
            {
                title: t('My opinion'),
                url: `/${language}/polls`,
                component: PollsPage,
                areas: [`header-icons`],
                icon: <PollIcon/>,
                full: true,
            },
            {
                title: t('Messages'),
                url: `/${language}/dialogs`,
                component: ChatPage,
                areas: [`header-icons`],
                icon: <MessageIcon/>,
                full: true,
            },
            // {
            //     title: t('Notifications'),
            //     url: `/${language}/notifications`,
            //     component: ConstructionPage,
            //     areas: [`header-icons`],
            //     icon: <NotificationsIcon/>,
            //     full: true,
            // },
            {
                title: t('Calendar'),
                url: `/${language}/calendar`,
                component: CalendarPage,
                areas: [`header-icons`],
                icon: <CalendarTodayIcon/>
            },
            {
                title: t('Profile'),
                url: `/${language}/profile`,
                component: ProfilePage,
                areas: [`profile`],
                icon: <AccountCircleIcon/>,
                subMenu: {
                    items: [
                        {
                            title: t('Personal information'),
                            url: `/${language}/personal-data`,
                            component: PersonalDataPage,
                        },
                        // {
                        //     title: t('Profile settings'),
                        //     url: `/${language}/profile-settings`,
                        //     component: ConstructionPage
                        // },
                        {
                            title: 'FAQ',
                            url: `/${language}/faq`,
                            component: FaqPage
                        },
                        {
                            title: 'Dubai Opera Ball',
                            url: `/${language}/dubai-opera-ball`,
                            component: DubaiOperaBallPage
                        },
                        {
                            title: 'Roscongress Club',
                            url: `/${language}/rc-club`,
                            component: RcClubPage
                        },
                        {
                            title: 'Roscongress Club - Upload Agreement',
                            url: `/${language}/rc-club/upload-agreement`,
                            component: RcClubUploadPage
                        },
                        {
                            title: 'Roscongress Club - Renew Card',
                            url: `/${language}/rc-club/renew`,
                            component: RcClubRenewCardPage
                        },
                        {
                            title: t('Information materials of the Roscongress Foundation'),
                            url: `/${language}/add-materials`,
                            component: InformationMaterialsPage
                        },
                        {
                            title: t('Contact'),
                            url: `/${language}/feedback`,
                            component: ContactPage
                        },
                    ]
                }
            },
            // {
            //     title: t('Settings'),
            //     url: `/${language}/construction1`,
            //     component: ConstructionPage,
            //     areas: [`profile`],
            //     icon: <SettingsIcon/>,
            // },
            {
                title: t('FAQ'),
                url: `/${language}/faq`,
                component: FaqPage,
                areas: [`profile`],
                icon: <LiveHelpIcon/>,
            },
            {
                title: t('Information materials of the Roscongress Foundation'),
                url: `/${language}/add-materials`,
                component: InformationMaterialsPage,
                areas: [`profile`],
                icon: <InfoIcon/>,
            },
            {
                title: t('Contact'),
                url: `/${language}/contact_message`,
                component: ContactPage,
                areas: [`profile`],
                icon: <ImportContactsIcon/>,
            },
            {
                url: `/${language}/biography`,
                component: BiographyPage,
            },
            {
                url: `/${language}/networking`,
                component: NetworkingPage,
            },
            {
                url: `/${language}/materials/view`,
                component: MaterialPage,
            },
            {
                url: `/${language}/dopayment`,
                component: FakeFormPage,
            },
            {
                url: `/${language}/payment/:code`,
                component: PaymentPage,
            },
            {
                url: `/${language}/maintenance`,
                component: MaintenancePage,
            },
            {
                url: `/${language}/about-account`,
                component: AboutAccountPage,
            },
            {
                url: '/registration_participant_elk',
                component: RegList,
            },
            {
                url: `/${language}/restore-password`,
                component: RestorePassword
            },
            {
                url: `/${language}/mobile/registration`,
                component: MobileRegistrationPage,
            },
            {
                url: `/${language}/mobile/personal-info`,
                component: MobilePersonalPage
            },
            {
                url: `/${language}/mobile/under-construction`,
                component: MobileConstructionPage
            },
            {
                url: `/${language}/mobile/profile`,
                component: MobilePersonalDataPage
            },
            {
                url: `/${language}/mobile/chat`,
                component: MobileChatPage
            },
            {
                url: `/${language}/mobile/card/:id`,
                component: MobileBusinessCardPage
            },
            {
                url: `/${language}/mobile/event/speakers`,
                component: MobileSpeakersPage
            },
            {
                url: `/${language}/mobile/event/speakers/card`,
                component: MobileSpeakersCardPage
            },
            {
                url: `/${language}/mobile/event/partners`,
                component: MobilePartnersPage
            },
            {
                url: `/${language}/mobile/news`,
                component: MobileNewsPage
            },
            {
                url: `/${language}/mobile/news/content`,
                component: MobileNewsContentPage
            },
            {
                url: `/${language}/mobile/analytics`,
                component: MobileAnalyticsPage
            },
            {
                url: `/${language}/mobile/personal-profile`,
                component: MobilePersonalProfilePage
            },
            {
                url: `/${language}/mobile/visit-card/:type`,
                component: MobileVisitCardPage
            },
            {
                url: `/${language}/mobile/qr/:type`,
                component: MobileQrPage
            },
            {
                url: `/${language}/mobile/about/projects`,
                component: MobileAboutProjectsPage
            },
            {
                url: `/${language}/mobile/about/projects/event1`,
                component: MobileAboutEvent1Page
            },
            {
                url: `/${language}/mobile/about/faq`,
                component: MobileAboutFaqPage
            },
            {
                url: `/${language}/mobile/about/contacts`,
                component: MobileAboutContactsPage
            },
            {
                url: `/${language}/mobile/services`,
                component: MobileServicesPage
            },
            {
                url: `/${language}/mobile/about`,
                component: MobileAboutPage
            },
            {
                url: `/${language}/mobile/calendar`,
                component: MobileCalendarPage
            },
            {
                url: `/${language}/mobile/event/:code`,
                component: MobileJusticeITEventPage,
            },
            {
                url: `/${language}/mobile/survey/:project/:id?`,
                component: MobileJusticeITEventSurveyPage,
            },
            {
                url: `/${language}/mobile/home`,
                component: MobileHomePage
            },
            {
                url: `/${language}/mobile/notifications`,
                component: MobileNotificationsPage
            },
            {
                url: `/${language}/mobile/restore-password`,
                component: MobileRestorePasswordPage
            },
            {
                url: `/${language}/mobile/myevents`,
                component: MobileMyEventsPage
            },
            {
                url: `/${language}/mobile/statuses`,
                component: MobileStatusesPage
            },
            {
                url: `/${language}/mobile/bdk`,
                component: MobileBdkPage
            },
            {
                url: `/${language}/sme-cooperation/`,
                component: SmeMainPage,
                full: true,
            },
            {
                url: `/${language}/sme-cooperation/create`,
                component: SmeCreateFormPage,
                full: true,
            },
            {
                url: `/${language}/sme-cooperation/list`,
                component: SmeCardsCategoryPage,
                full: true,
            },
            {
                url: `/${language}/sme-cooperation/list/:category`,
                component: SmeViewCategoryPage,
                full: true,
            },
            {
                url: `/${language}/sme-cooperation/card/:category/:id`,
                component: SmeViewCardPage,
                full: true,
            },
            {
                url: `/${language}/sme-cooperation/success`,
                component: SmeSuccessMessagePage,
                full: true,
            },
            {
                url: `/${language}/sme-cooperation/my`,
                component: SmeMyCardsPage,
                full: true,
            },
            {
                url: `/${language}/sme-cooperation/about`,
                component: AboutMSPPage,
                full: true,
            },
            {
                url: '/log-out',
                component: LoaderPage
            }
        ];
    }

    getRouterItems() {
        let routerItems = [];
        this.items.forEach(menuItem => {
            let item = Object.assign({}, menuItem), items = [];
            if (!!item.subMenu) {
                items = items.concat(item, item.subMenu.items);
            } else {
                items.push(item);
            }
            routerItems = routerItems.concat(items);
        });
        return routerItems;
    }

    getHeaderItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('header') !== -1);
    }

    getHeaderIconsItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('header-icons') !== -1);
    }

    getProfileItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('profile') !== -1);
    }
}

export const mainMenu = new MainMenu();
