export function membersHasErrored(state = false, action) {
    switch (action.type) {
        case 'MEMBERS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function membersIsLoading(state = false, action) {
    switch (action.type) {
        case 'MEMBERS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function membersData(state = [], action) {
    switch (action.type) {
        case 'MEMBERS_FETCH_DATA_SUCCESS':
            return action.data;

        default:
            return state;
    }
}

export function membersProjectData(state = [], action) {
    switch (action.type) {
        case 'MEMBERS_FETCH_PROJECT_SUCCESS':
            return action.projects;

        default:
            return state;
    }
}

const dialogInitialState = {
        open: false,
        isError: false,
        isLoading: false,
        memberData: {}
};

export function membersCardDialog(state = dialogInitialState, action) {
    switch (action.type) {
        case 'MEMBERS_DIALOG_OPEN':
            return Object.assign({}, state, {open: true});
        case 'MEMBERS_DIALOG_CLOSE':
            return Object.assign({}, state, {open: false});
        case 'MEMBERS_DIALOG_DATA':
            return Object.assign({}, state, {memberData: action.memberData});
        case 'MEMBERS_DIALOG_LOADING':
            return Object.assign({}, state, {isLoading: action.isLoading});
        case 'MEMBERS_DIALOG_ERROR':
            return Object.assign({}, state, {isError: action.isError});
        default:
            return state;
    }
}