import React, { Suspense } from 'react';
import Loader from '../components/Loader';
// import Loading from './Loading';

const Loadable = (Component) => (props) => (
    <Suspense fallback={<Loader isOpen={true} />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
