export function eventsHasErrored(state = false, action) {
    switch (action.type) {
        case 'EVENTS_ALL_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function eventsIsLoading(state = false, action) {
    switch (action.type) {
        case 'EVENTS_ALL_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function eventsAll(state = [], action) {
    switch (action.type) {
        case 'EVENTS_ALL_FETCH_DATA_SUCCESS':
            return action.eventsAll;

        default:
            return state;
    }
}
