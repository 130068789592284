import React from "react";

import { Modal } from "@material-ui/core";
import { ReactComponent as LoaderIcon } from "../../static/icons/LoaderIcon.svg";
// import { ReactComponent as LoaderIcon } from "../../static/logos/LoaderRed.svg";
import styles from "./styles.module.scss";

export type LoaderProps = {
    isOpen: boolean;
};

const Loader: React.FC<LoaderProps> = ({ isOpen }) => {
    return (
        <Modal open={isOpen} BackdropProps={{ invisible: true }}>
            <div className={styles.wrapper}>
                <LoaderIcon />
            </div>
        </Modal>
    );
};

export default Loader;
