const initialState = {
    loading: false,
    modal: true,
    data: {},
    agreement: null,
    treatmentAndName: ''
}

export const rcClub = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return {...state, data: action.data}
        case 'SET_LOADING':
            return {...state, loading: action.loading}
        case 'SET_MODAL':
            return {...state, modal: action.modal}
        case 'SET_AGREEMENT_ID':
            return {...state, agreement: action.agreement}
        case 'SET_TREATMENT_AND_NAME':
            return {...state, treatmentAndName: action.treatmentAndName}
        default:
            return state;
    }
}
