import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import footerLogo from '../../static/footer_logo.svg';
import footerLogoEn from '../../static/footer_logo_en.svg';
// import iconYouTube from '../../static/icons/icon-youtube.svg';
// import iconFacebook from '../../static/icons/icon-facebook.svg';
// import iconTwitter from '../../static/icons/icon-twitter.svg';
// import iconZen from '../../static/icons/icon-zen.svg';
// import iconInstagram from '../../static/icons/icon-instagram.svg';
import iconTelegram from '../../static/icons/icon-telegram.svg';
import iconVk from '../../static/icons/icon-vk.svg';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {language, t} from "@ksbteam/core/api/DummyTranslate";

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(4),
    },
    title: {
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '2em',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
            maxWidth: 900,
            marginBottom: '2em',
        },
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    successMessage: {
        maxWidth: 900,
        margin: '48px auto'
    },
    formWrapper: {
        margin: '0 auto',
    },
    formButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(4)
    },
    formButton: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    helpTextLink: {
        fontSize: '0.84em'
    },
    photoRequirements: {
        fontSize: '0.8em',
        textAlign: 'center',
        '& a': {
            textDecoration: 'underline',
            textDecorationStyle: 'dashed'
        },
        [`@media (min-width: 960px)`]: {
            display: 'none'
        }
    },
    helpIcon: {
        position: 'relative',
        top: 6,
        marginRight: 4,
        color: '#a50e2d'
    },
    footer: {
        padding: theme.spacing(4, 2),
        marginTop: 'auto',
        backgroundColor: '#201f24',
        color: 'gray',
        '& a': {
            textDecoration: 'none',
            color: 'gray',
            '&:hover': {
                color: '#f2f2f2',
                opacity: 1
            }
        }
    },
    blockInfo: {
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        fontSize: 16,
        lineHeight: '27px',
    },
    copyYears: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    copyBottom: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        '& p': {
            fontSize: 11
        }
    },
    itemSocialBlock: {
        width: '100%'
    },
    itemSocial: {
        '& div[class*="iconBlock"]': {
            opacity: .4
        },
        '&:hover': {
            '& div[class*="iconBlock"]': {
                opacity: 1
            },
        }
    },
    blockSocial: {
        display: 'block',
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(3),
        fontSize: 16,
        lineHeight: '27px',
        borderLeft: '1px #3f3d3d solid',
        '& div': {
            display: 'inline-block',
            backgroundSize: 'contain',
        },
        [`@media (max-width: 600px)`]: {
            paddingLeft: 0,
            borderLeft: 'none',
        }
    },
    iconBlock: {
        width: 18,
        height: 18,
    },
    // iconYouTube: {
    //     position: 'relative',
    //     top: 1,
    //     width: 18,
    //     height: 14,
    //     background: `url(${iconYouTube}) 0 0 no-repeat`,
    // },
    // iconFacebook: {
    //     position: 'relative',
    //     left: 4,
    //     top: 2,
    //     width: 10,
    //     height: 17,
    //     background: `url(${iconFacebook}) 0 0 no-repeat`,
    // },
    // iconTwitter: {
    //     position: 'relative',
    //     width: 18,
    //     height: 14,
    //     background: `url(${iconTwitter}) 0 0 no-repeat`,
    // },
    // iconZen: {
    //     position: 'relative',
    //     top: 3,
    //     width: 16,
    //     height: 17,
    //     background: `url(${iconZen}) 0 0 no-repeat`,
    // },
    // iconInstagram: {
    //     position: 'relative',
    //     top: 3,
    //     width: 16,
    //     height: 16,
    //     background: `url(${iconInstagram}) 0 0 no-repeat`,
    // },
    iconTelegram: {
        position: 'relative',
        top: 3,
        width: 16,
        height: 15,
        background: `url(${iconTelegram}) 0 0 no-repeat`,
    },
    iconVk: {
        position: 'relative',
        top: 2,
        width: 16,
        height: 15,
        background: `url(${iconVk}) 0 0 no-repeat`
    },
    titleSocial: {
        paddingLeft: 18,
        display: 'inline-block',
        [`@media (max-width: 320px)`]: {
            paddingLeft: 2,
        }
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    containerMiddle: {
        paddingBottom: theme.spacing(2),
        borderBottom: '1px rgba(210,213,221,.3) solid'
    },
    footerLogoBlock: {
        paddingBottom: theme.spacing(4),
        borderBottom: '1px rgba(210,213,221,.3) solid'
    },
    footerLogo: {
        display: 'block',
        background: `url(${language === 'ru' ? footerLogo : footerLogoEn}) no-repeat 0 0 transparent`,
        backgroundSize: 'contain',
        opacity: .4,
        width: 229,
        height: 43,
        textDecoration: 'none'
    },
    headLogo: {
        minHeight: 30,
        position: 'relative',
        display: 'block',
        textDecoration: 'none',
        '& img': {
            display: 'block',
            width: 228,
            height: '100%',
            position: 'relative',
            left: 0
        }
    },
    header: {
        padding: theme.spacing(4, 2),
    },
    top: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        alignItems: 'stretch',
        flex: '0 0 auto',
        position: 'relative',
        height: 78
    }
});

class Footer extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <footer className={classes.footer}>
                <Container maxWidth="md">
                    <Box className={classes.footerLogoBlock}>
                        <Link className={classes.footerLogo} href={language === 'ru' ? 'https://roscongress.org' : 'https://roscongress.org/en'}>{' '}</Link>
                    </Box>
                    <Grid className={classes.containerMiddle} container spacing={0}>
                        <Grid item sm={8} xs={12}>
                            <Box className={classes.blockInfo}>
                                <Typography>{language === 'ru' ? 'Тел./факс' : 'Tel./Fax'}: +7 (495) 640 4440</Typography>
                                <Typography>Email: <a href="mailto:info@roscongress.org">info@roscongress.org</a></Typography>
                                <Typography className={classes.copyYears}>&copy; 2007–{(new Date().getFullYear())}</Typography>
                                <Typography>{t('If you have any comments or suggestions for improvements to the Roscongress personal account, please write to us at')} <a href='mailto:regsupport@roscongress.org'>regsupport@roscongress.org</a></Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Box className={classes.blockSocial}>

                                <Box className={classes.itemSocialBlock}>
                                    <Box className={classes.itemSocial}>
                                        <a href={language === 'ru' ? 'https://t.me/roscongress' : 'https://t.me/RoscongressDirect'} target={'_blank'} rel={'noopener noreferrer'}>
                                            <Box className={classes.iconBlock}>
                                                <Box className={classes.iconTelegram} />
                                            </Box>
                                            <Box className={classes.titleSocial}>Telegram</Box>
                                        </a>
                                    </Box>
                                </Box>

                                <Box className={classes.itemSocialBlock}>
                                    <Box className={classes.itemSocial}>
                                        <a href={language === 'ru' ? 'https://vk.com/public211047807' : 'https://vk.com/public211047807'} target={'_blank'} rel={'noopener noreferrer'}>
                                            <Box className={classes.iconBlock}>
                                                <Box className={classes.iconVk} />
                                            </Box>
                                            <Box className={classes.titleSocial}>{language === 'ru' ? 'ВКонтакте' : 'VK'}</Box>
                                        </a>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                    <Box className={classes.copyBottom}>
                        <Typography>{t('Materials on the website may only be used with written permission from the Roscongress Foundation or the corresponding copyright holder')}.</Typography>
                        <Typography>{t('The use of any services to automatically extract information from the website without the express permission of the Roscongress Foundation is prohibited')}.</Typography>
                    </Box>
                </Container>
            </footer>
        );
    }
}

export default withStyles(styles)(Footer);
